import { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { useToast, ToastAction } from '@/components/ui/toast';

import { log } from '@/utils/logger';

const CHECK_FOR_UPDATE_INTERVAL = 1000 * 60 * 30;

/**
 * This component shows a toast when a new version of the application is available.
 * How it works
 * - When application is loaded and new version has NOT been published:
 *   - `useRegisterSW` sets `needRefresh=false`, nothing happens
 * - When application is loaded and new version has been published:
 *   - `useRegisterSW` sets `needRefresh=true`
 *     - a toast is shown to user
 *     - if user clicks refresh button, page is reloaded with new version of app
 *     - if user dismisses the toast, it is shown again when app is loaded next time
 * - While application is running:
 *   - `setInterval` is used to check for updates
 *   - if new version is available, `useRegisterSW` sets `needRefresh=true`
 *   - toast logic works the same as in application load case
 *   - if user dismisses the toast:
 *     - it is shown again when app is loaded next time
 *     - it is not shown one more time when app receives one more update
 *
 * How to test
 * When using dev server, SW experience can be confusing as there is also Hot Module Replacement involved
 * To test, one can use `pnpm build && pnpm preview`
 */

// see https://vite-pwa-org.netlify.app/frameworks/react.html
export const ReloadPrompt = () => {
  const { toast } = useToast();
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swURL, swRegistration) {
      log('onRegistered', swRegistration);

      if (!swRegistration) return;

      log('Starting periodic update', swRegistration);

      // This part checks for app updates which might be published while app is open
      //   and triggers hook update (`needRefresh` change to true)
      // See https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
      setInterval(async () => {
        log('Check for update');

        if (!(!swRegistration.installing && navigator)) return;

        if ('connection' in navigator && !navigator.onLine) return;

        log('Check for update: fetching');

        const resp = await fetch(swURL, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        });

        log('Check for update: response', resp);

        if (resp?.status === 200) await swRegistration.update();
      }, CHECK_FOR_UPDATE_INTERVAL);
    },
    onRegisterError(error) {
      throw new Error('ReloadPrompt: ServiceWorker registration error', {
        cause: error,
      });
    },
    onNeedRefresh() {
      log('onNeedRefresh');
    },
    onOfflineReady() {
      log('Ready to work offline');
    },
  });

  log({ needRefresh, offlineReady });

  useEffect(() => {
    if (needRefresh) {
      const ignoreRefreshRequest = () => {
        setOfflineReady(false);
        setNeedRefresh(false);
      };

      // Running this from `onNeedRefresh` could be nice
      //   but it doesn't have access to `updateServiceWorker`
      toast({
        title: 'App update needed',
        description: 'There is a new application version available.',
        action: (
          <ToastAction altText='Refresh' onClick={() => updateServiceWorker()}>
            Update
          </ToastAction>
        ),
        onOpenChange: (isOpen) => {
          if (!isOpen) ignoreRefreshRequest();
        },
        duration: Infinity, // show until user closes the toast
      });
    }
  }, [
    needRefresh,
    setOfflineReady,
    setNeedRefresh,
    toast,
    updateServiceWorker,
  ]);

  // This component just shows a toast, no render.
  return null;
};
